<template>
  <div class="sld_msg_list flex-row">
    <div class="left">
      <div class="title flex-x-between flex-y-center">
        <div>信息</div>
        <img src="../../assets/member/du.png" alt="" />
      </div>
      <div class="flex-y-center" style="height:64px;padding-left:16px;color:#919191;font-size:14px">
        <img src="https://test-img.easybuykz.com/b2b2cdev/images/h5/order/notifications.png" style="width:24px;height:24px;margin-right:8px;" alt="">
        通知
      </div>
      <div v-for="(item,index) in menuList" :key="index" class="flex-y-center menu" :class="{active:item.value === tplType}" @click="handleChange(item)">
        <img :src="item.url" alt="">
        <div class="AlibabaSans-Bold" style="font-size:14px">{{item.name}}</div>
        <img src="https://test-img.easybuykz.com/b2b2cdev/images/h5/order/Verified.png" style="width:16px;height:16px" alt="">
      </div>
    </div>

    <div class="msg_list" v-if="noticeList.data && noticeList.data.length > 0">
      <div class="flex-y-center" style="height:168px">
        <img :src="menuList.find(i=>i.value === tplType).url" style="width:64px;margin-right:16px" alt="">
        <div class="AlibabaSans-Bold" style="font-size:16px">{{menuList.find(i=>i.value === tplType).name}}</div>
        <img src="https://test-img.easybuykz.com/b2b2cdev/images/h5/order/Verified.png" style="width:24px;height:24px;margin-left:8px" alt="">
      </div>
      <div class="msg_list_pre" v-for="(noticeItem, noticeIndex) in noticeList.data" :key="noticeIndex" @click="goMsgDetail(noticeItem)">
        <p class="msg_pre_time">{{ $formatDate(noticeItem.msgSendTime,true) }}</p>
        <div class="msg_pre">
          <!-- <div class="msg_pre_title flex_row_between_center">
              <el-checkbox v-model="noticeItem.checked" class="pre_title_left" @change="noticeChecked()">{{
                noticeItem.tplName }}</el-checkbox>
              <el-popconfirm :confirmButtonText="L['确定']" :cancelButtonText="L['取消']" icon="el-icon-info" iconColor="red" :title="L['确定删除这条消息吗？']" @confirm="delMsg(noticeItem.receiveId)">
                <template #reference><i class="iconfont iconziyuan51 pre_title_right cursor_pointer"></i></template>
              </el-popconfirm>
            </div> -->
          <!-- 	消息状态：0--未读，1--已读，2--删除 -->
          <div class="msg_content flex_row_between_center">
            <div class="flex_row_start_start">
              <p>
                {{ noticeItem.msgContent }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex_row_end_center sld_pagination sld_page_bottom" v-if="noticeData.data &&
          noticeData.data.pagination &&
          noticeData.data.pagination.total
          ">
        <el-pagination @current-change="handleCurrentChange" v-model:currentPage="current" :page-size="pageSize" layout="prev, pager, next, jumper" :total="noticeData.data.pagination.total"
          :hide-on-single-page="true">
        </el-pagination>
      </div>
    </div>
    <SldCommonEmpty v-if="!noticeList.data || (noticeList.data && noticeList.data.length == 0)
      " totalWidth="900" totalHeight="300" style="color:#666" :tip="L['暂时没有消息！']" />
  </div>
</template>
<script>
import { ElMessage } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import {
  reactive,
  getCurrentInstance,
  onMounted,
  ref,
  watchEffect,
  watch
} from 'vue'
import SldCommonEmpty from '../../components/SldCommonEmpty'
export default {
  components: {
    SldCommonEmpty
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    const router = useRouter()
    const L = proxy.$getCurLanguage()
    const current = ref(1)
    const pageSize = ref(10)
    const noticeList = reactive({ data: [] }) //消息列表
    const checkedAll = ref(false) //全选是否选中
    const noticeData = reactive({ data: {} }) //消息数据
    const receiveIds = ref('') //被选中的id集
    const tplType = ref('system_news')
    const menuList = ref([
      { name: '系统消息', value: 'system_news', url: 'https://test-img.easybuykz.com/b2b2cdev/images/h5/order/chat01.png' },
      { name: '订单消息', value: 'order_news', url: 'https://test-img.easybuykz.com/b2b2cdev/images/h5/order/chat02.png' },
      { name: '资产消息', value: 'assets_news', url: 'https://test-img.easybuykz.com/b2b2cdev/images/h5/order/chat03.png' },
      { name: '预约提醒', value: 'appointment_news', url: 'https://test-img.easybuykz.com/b2b2cdev/images/h5/order/chat04.png' },
      { name: '售后消息', value: 'after_sale_news', url: 'https://test-img.easybuykz.com/b2b2cdev/images/h5/order/chat05.png' },
    ])
    const listTitleMap = ref(new Map())
    listTitleMap.value.set('msg_all', L['消息列表'])
    listTitleMap.value.set('system_news', L['系统消息'])
    listTitleMap.value.set('order_news', L['订单消息'])
    listTitleMap.value.set('assets_news', L['资产消息'])
    listTitleMap.value.set('after_sale_news', L['售后消息'])
    listTitleMap.value.set('appointment_news', L['预约提醒'])
    onMounted(() => {
      getMsglist()
    })
    //获取消息列表
    const getMsglist = () => {
      let params = {
        pageSize: pageSize.value,
        current: current.value,
        tplType: tplType.value
      }

      proxy.$get('v3/msg/front/msg/msgList', params).then(res => {
        if (res.state == 200) {
          let result = res.data
          noticeData.data = result
          noticeList.data = result.list
          noticeList.data.map(noticeItem => {
            noticeItem.checked = false
          })
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const checkMsgAll = () => {
      //全选状态是否选中
      if (checkedAll.value == true) {
        noticeList.data.map(noticeItem => {
          noticeItem.checked = true
        })
      } else {
        noticeList.data.map(noticeItem => {
          noticeItem.checked = false
        })
      }
    }

    //消息状态处理
    const noticeChecked = () => {
      let noCheckList = []
      noCheckList = noticeList.data.filter(
        noticeItem => noticeItem.checked == false
      )

      if (noCheckList.length > 0) {
        checkedAll.value = false
      } else {
        checkedAll.value = true
      }
    }
    //上一页
    const handlePrevCilickChange = () => {
      checkedAll.value = false
      current.value--
      getMsglist()
    }
    //下一页
    const handleNextCilickChange = () => {
      checkedAll.value = false
      current.value++
      getMsglist()
    }
    //当前页
    const handleCurrentChange = () => {
      checkedAll.value = false
      getMsglist()
    }
    //选中的id集
    const checkReceiveID = () => {
      let checkList = []
      checkList = noticeList.data.filter(
        noticeItem => noticeItem.checked == true
      )
      let receiveIdsData = []
      checkList.map(checkItem => {
        receiveIdsData.push(checkItem.receiveId)
      })
      receiveIds.value = receiveIdsData.join(',')
    }
    //删除消息
    const delMsg = receiveId => {
      let params = {}
      if (receiveId) {
        params = {
          receiveIds: receiveId
        }
        delMsgMe(params)
        return
      }
      checkReceiveID()
      if (receiveIds.value) {
        params = {
          receiveIds: receiveIds.value
        }
        proxy
          .$confirm(L['确定删除这些消息吗'], L['提示'], {
            confirmButtonText: L['确定'],
            cancelButtonText: L['取消'],
            type: 'warning'
          })
          .then(() => {
            delMsgMe(params)
          })
      } else {
        ElMessage.error(L['请先选择要操作的消息！'])
      }
    }

    const delMsgMe = params => {
      proxy.$post('v3/msg/front/msg/del', params).then(res => {
        if (res.state == 200) {
          ElMessage.success(res.msg)
          getMsglist()
          proxy.$getUserInfo()
          checkedAll.value = false
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    //标为已读
    const readMsg = () => {
      checkReceiveID()
      let params = {
        receiveIds: receiveIds.value
      }
      if (receiveIds.value) {
        proxy.$post('v3/msg/front/msg/read', params).then(res => {
          if (res.state == 200) {
            ElMessage.success(res.msg)
            checkedAll.value = false
            getMsglist()
            proxy.$getUserInfo()
          } else {
            ElMessage.error(res.msg)
          }
        })
      } else {
        ElMessage.error(L['请先选择要操作的消息！'])
      }
    }
    //全部标为已读
    const allMsg = () => {
      let params = {
        isAllRead: true,
        isPc: true,
      }
      proxy.$post('v3/msg/front/msg/read', params).then(res => {
        if (res.state == 200) {
          ElMessage.success(res.msg)
          checkedAll.value = false
          getMsglist()
          proxy.$getUserInfo()
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const readMsgNOTip = () => {
      checkReceiveID()
      let params = {
        receiveIds: receiveIds.value
      }
      if (receiveIds.value) {
        proxy.$post('v3/msg/front/msg/read', params).then(res => {
          if (res.state == 200) {
            getMsglist()
            proxy.$getUserInfo()
          }
        })
      }
    }

    const handleChange = (item) => {
      tplType.value = item.value
      current.value = 1
      getMsglist()
    }

    //消息跳转
    const goMsgDetail = noticeItem => {
      let msgLinkInfo = JSON.parse(noticeItem.msgLinkInfo)
      let curNotice = [] //当前消息
      curNotice = noticeList.data.filter(
        item => item.receiveId == noticeItem.receiveId
      )
      if (curNotice && curNotice.length > 0) {
        curNotice[0].checked = true
        readMsgNOTip()
      }
      let newWin = null
      switch (msgLinkInfo.type) {
        //仅退款
        case 'order_news':
          if (
            msgLinkInfo.orderType &&
            msgLinkInfo.orderType == 'integral_order'
          ) {
            newWin = router.resolve({
              path: '/member/pointOrder/detail',
              query: {
                orderSn: msgLinkInfo.orderSn
              }
            })
          } else {
            newWin = router.resolve({
              path: '/member/order/detail',
              query: {
                orderSn: msgLinkInfo.orderSn
              }
            })
          }
          break

        case 'balance_change':
          newWin = router.resolve({
            path: '/member/balance'
          })
          break

        case 'coupon_news':
          newWin = router.resolve({
            path: '/member/coupon'
          })
          break
        //退款退货
        case 'return_news':
          newWin = router.resolve({
            path: '/member/order/refund/detail',
            query: {
              afsSn: msgLinkInfo.afsSn,
              curTitleId: 2
            }
          })
          break

        case 'integral_change':
          newWin = router.resolve({
            path: '/member/myPoint'
          })
          break

        case 'appointment_news':
          newWin = router.resolve({
            path: '/goods/detail',
            query: {
              productId: msgLinkInfo.productId
            }
          })
          break

        case 'refund_news':
          newWin = router.resolve({
            path: '/member/order/refund/detail',
            query: {
              afsSn: msgLinkInfo.afsSn,
              curTitleId: 1
            }
          })
          break
      }
      window.open(newWin.href, '_blank')
    }
    return {
      current,
      pageSize,
      noticeList,
      checkedAll,
      noticeChecked,
      noticeData,
      handlePrevCilickChange,
      handleNextCilickChange,
      handleCurrentChange,
      delMsg,
      receiveIds,
      readMsg,
      tplType,
      L,
      goMsgDetail,
      checkMsgAll,
      allMsg,
      menuList,
      handleChange
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../style/base.scss";
@import "../../style/iconfont.css";
// 消息列表
.sld_msg_list {
  width: 1040px;
  float: right;
  border: 1px solid #f1f1f1;
  border-radius: 12px;
  .left {
    width: 312px;
    border-right: 1px solid #f1f1f1;
    .title {
      width: 100%;
      height: 64px;
      padding: 0 16px;
      font-size: 24px;
      border-bottom: 1px solid #f1f1f1;
    }
    .menu {
      width: 296px;
      height: 64px;
      padding: 0 8px;
      border-radius: 12px;
      margin: 2px auto;
      cursor: pointer;

      &.active,
      &:hover {
        background: #dbe1ff;
      }
      img {
        width: 48px;
        height: 48px;
        margin: 12px;
      }
    }
  }

  .msg_list {
    padding-left: 24px;

    .msg_list_pre {
      width: 424px;
      margin-bottom: 20px;
      border: 2px solid #eff0ff;
      border-radius: 12px;
      padding: 16px;
      cursor: pointer;

      .msg_pre_time {
        color: #919191;
        font-size: 12px;
        margin-bottom: 8px;
      }

      .msg_pre {
        margin: 0 auto;

        .msg_pre_title {
          height: 40px;
          line-height: 39px;
          border-bottom: 1px solid #dcdcdc;
          background-color: #f7f7f7;
          color: #666666;
          padding: 0 20px;

          .pre_title_right {
            font-size: 12px;
          }
        }

        .msg_content {
          color: #1b1b1b;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  .cursor_pointer {
    cursor: pointer;
  }

  .sld_page_bottom {
    margin: 20px 60px 20px 20px;
  }
}
</style>
